import React, { useEffect, useState } from "react"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
const { SearchBar } = Search
import { Col, Row } from "reactstrap"
import { GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import Select from "react-select"
import { GetLoggedOnUser } from "helpers/misc_helpers"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"
import { columns } from "./columns"
import moment from "moment"
import "./appointments.scss"

function Appointments() {
  const setLoading = useLoading()
  const curUser = GetLoggedOnUser()
  const [data, setData] = useState([])
  const [areas, setAreas] = useState([])
  const [filteredData, setfilteredData] = useState([])
  const [users, setUsers] = useState([])
  const types = ["Alle", "Lieferung", "Reinigung", "Abholung"]
  const status = ["Alle", "offen", "abgeschlossen", "Nicht Erfolgreich"]
  const [reqObj, setReqObj] = useState({
    startDate: localStorage.getItem("startDate")
      ? new Date(localStorage.getItem("startDate"))
      : new Date(),
    endDate: localStorage.getItem("endDate")
      ? new Date(localStorage.getItem("endDate"))
      : new Date(),
  })
  const [filterObj, setFilterObj] = useState({
    area_id: localStorage.getItem("area_id")
      ? parseInt(localStorage.getItem("area_id"), 10)
      : -1,
    type: localStorage.getItem("type") || "Alle",
    status: localStorage.getItem("status") || "Alle",
  })
  // const [routeSort, setRouteSort] = useState(
  //   () => localStorage.getItem("routeSort") === "true"
  // )
  const [routeSort, setRouteSort] = useState(
    false
  )
  const [selectedUser, setSelectedUser] = useState(() => {
    const savedUser = JSON.parse(localStorage.getItem("selectedUser"))

    return (
      savedUser || {
        value: curUser?._id,
        label: "Meine Termine",
      }
    )
  })

  useEffect(() => {
    localStorage.setItem("startDate", reqObj.startDate)
    localStorage.setItem("endDate", reqObj.endDate)
    loadData()
    loadAreas()
    loadUsers()
  }, [reqObj, routeSort])

  useEffect(() => {
    localStorage.setItem("routeSort", routeSort) // Speichere den Switch-Status
  }, [routeSort])

  useEffect(() => {
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser))
  }, [selectedUser])

  useEffect(() => {
    if (filterObj?.area_id) localStorage.setItem("area_id", filterObj.area_id)
    if (filterObj?.type) localStorage.setItem("type", filterObj.type)
    if (filterObj?.status) localStorage.setItem("status", filterObj.status)
  }, [filterObj])

  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const loadData = async () => {
    setLoading(true)

    const data = await GetData(
      `/appointments?sort=${routeSort ? 1 : 0}&startDate=${
        reqObj?.startDate
      }&endDate=${reqObj?.endDate}` +
        (reqObj?.area_id ? `&area_id=${reqObj?.area_id}` : "")
    )
    setData(data)

    setLoading(false)
  }

  const loadUsers = async () => {
    const userData = await GetData(`/users`)
    setUsers([
      {
        value: -1,
        label: "Alle",
      },
      ...userData
        ?.filter(u => u.is_admin == 0)
        .map(a => ({ label: a.name, value: a.id })),
    ])
  }

  const loadAreas = async () => {
    const response = await GetData("/areas")
    setAreas([
      { label: "Alle", value: -1 },
      ...response?.map(a => ({ label: a.name, value: a.id })),
    ])
  }

  useEffect(() => {
    filterData()
  }, [selectedUser, data, filterObj])

  const filterData = () => {
    /** User Filter */
    let result
    if (selectedUser?.value == -1) result = data
    else result = data.filter(d => d.user_id == selectedUser?.value)

    /* Area Filter */
    if (filterObj.area_id > 0)
      result = result.filter(d => d.area_id == filterObj.area_id)

    /* Type Filter */
    if (filterObj.type && filterObj.type != "Alle")
      result = result.filter(d => d.type == filterObj.type)

    /* Status Filter */
    const status = mapStatusToCompletedField(filterObj.status)
    if (filterObj.status && filterObj.status != "Alle")
      result = result.filter(d => d.completed == status)

    setfilteredData(result)
  }

  const mapStatusToCompletedField = status => {
    if (status == "offen") return 0

    if (status == "abgeschlossen") return 1

    if (status == "Nicht Erfolgreich") return -1
  }

  const rowClasses = row => {
    if (row.completed === 1) return "bg-soft bg-success"
    if (row.completed == -1) return "bg-soft bg-warning"

    if (row.important) return "blinking-row"
    if (
      row.completed === 0 &&
      moment(row.date).isBefore(moment().startOf("day"))
    ) {
      return "blinking-row-warning"
    }
  }

  const countByType = type =>
    filteredData.filter(item => item.type === type).length

  return (
    <React.Fragment>
      <div id="appointments-page" className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions || [])}
                keyField="id"
                data={filteredData || []}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={filteredData}
                    remote={{ filter: true }}
                    columns={columns || []}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-3">
                          <Col className="px-1">
                            <h4>Termine</h4>
                            <span className="text-muted">
                              Reinigungen: {countByType("Reinigung")} /
                              Lieferung: {countByType("Lieferung")} /
                              Abholung: {countByType("Abholung")}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Suche:</label>
                                <SearchBar
                                  placeholder="Suche..."
                                  style={{
                                    width: "180px",
                                    float: "left !important",
                                  }}
                                  {...toolkitProps.searchProps}
                                />
                              </Col>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Typ:</label>
                                <div style={{ width: "180px" }}>
                                  <Select
                                    className="select2-selection w-100"
                                    value={{
                                      value: filterObj?.type,
                                      label: filterObj?.type,
                                    }}
                                    onChange={selectedOption => {
                                      setFilterObj({
                                        ...filterObj,
                                        type: selectedOption.value,
                                      })
                                    }}
                                    options={types.map(type => {
                                      return { label: type, value: type }
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Status:</label>
                                <div style={{ width: "180px" }}>
                                  <Select
                                    className="select2-selection w-100"
                                    value={{
                                      value: filterObj?.status,
                                      label: filterObj?.status,
                                    }}
                                    onChange={selectedOption => {
                                      setFilterObj({
                                        ...filterObj,
                                        status: selectedOption.value,
                                      })
                                    }}
                                    options={status.map(status => {
                                      return {
                                        label: status,
                                        value: status,
                                      }
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Bereich:</label>
                                <div style={{ width: "180px" }}>
                                  <Select
                                    className="select2-selection w-100"
                                    value={{
                                      value: filterObj?.area_id,
                                      label: areas?.find(
                                        a => a.value == filterObj?.area_id
                                      )?.label,
                                    }}
                                    onChange={selectedOption => {
                                      setFilterObj({
                                        ...filterObj,
                                        area_id: selectedOption.value,
                                      })
                                    }}
                                    options={areas}
                                  />
                                </div>
                              </Col>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Mitarbeiter:</label>
                                <div style={{ width: "180px" }}>
                                  <Select
                                    className="select2-selection w-100"
                                    value={selectedUser}
                                    onChange={selectedOption => {
                                      setSelectedUser(selectedOption)
                                    }}
                                    options={users}
                                  />
                                </div>
                              </Col>
                              <Col
                                className="px-1"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Datum:</label>
                                <Flatpickr
                                  className="form-control d-block"
                                  style={{ maxWidth: "190px" }}
                                  placeholder="Datumbereich"
                                  options={{
                                    mode: "range",
                                    dateFormat: "d.m.Y",
                                    locale: German,
                                    defaultDate: [new Date(), new Date()],
                                  }}
                                  value={[
                                    reqObj?.startDate,
                                    reqObj?.endDate,
                                  ]}
                                  onChange={dates => {
                                    if (dates?.length === 2)
                                      setReqObj({
                                        ...reqObj,
                                        startDate: dates[0],
                                        endDate: dates[1],
                                      })
                                  }}
                                />
                              </Col>
                              {/* <Col
                                className="px-2"
                                style={{ maxWidth: "190px" }}
                              >
                                <label>Routenpl.:</label>
                                <div className="form-check form-switch form-switch-lg mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={routeSort}
                                    onChange={e =>
                                      setRouteSort(e.target.checked)
                                    }
                                  />
                                </div>
                              </Col> */}
                            </Row>
                          </Col>
                        </Row>

                        <div className="table-responsive">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            bordered={false}
                            rowClasses={rowClasses}
                            noDataIndication={
                              "Es sind keine Termine vorhanden!"
                            }
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                          />
                        </div>
                        <div className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Appointments

import UserInfoModal from "components/Common/UserInfoModal"
import {
  CreateEntry,
  GetFacilitiesByName,
  UpdateEntry,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import AsyncSelect from "react-select/async"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const BookingModal = ({
  modal,
  onSuccess,
  onClose,
  location,
  selectedEntry,
}) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})
  const [error, setError] = useState()

  const handleSave = async () => {
    setLoading(true)
    console.log(reqObj)
    const errorMsg = checkReqObj(reqObj)
    if (errorMsg) {
      setLoading(false)
      return setError(errorMsg)
    }

    if (selectedEntry) await UpdateEntry("/bookings/" + reqObj.id, reqObj)
    else await CreateEntry("/bookings/", reqObj)

    onSuccess()
  }

  const checkReqObj = () => {
    if (
      !reqObj.pickup_date ||
      !reqObj.delivery_date ||
      !reqObj.facility_id ||
      !reqObj.quantity
    )
      return "Bitte alle Pflichtfelder befüllen!"

    if (
      isNaN(reqObj.flatrate) ||
      isNaN(reqObj.daily_rate) ||
      isNaN(reqObj.pickup_rate) ||
      isNaN(reqObj.delivery_rate)
    )
      return "Ungültiges Zahlenformat!"

    const pickupDate = moment.utc(reqObj.pickup_date).startOf('day')
    const deliveryDate = moment.utc(reqObj.delivery_date).startOf('day')
    if (deliveryDate.isAfter(pickupDate))
      return "Das Abholdatum darf nicht vor dem Lieferdatum liegen!"

    // Validate maintenance intervals
    for (const interval of reqObj.maintenance_intervals) {
      if (
        !interval.start_date ||
        !interval.end_date ||
        !interval.interval_days
      ) {
        return "Bitte alle Wartungsintervall-Felder befüllen!"
      }
      if (moment.utc(interval.end_date).startOf('day').isBefore(moment.utc(interval.start_date).startOf('day'))) {
        return "Das Enddatum eines Wartungsintervalls muss nach dem Startdatum liegen!"
      }
    }

    return null
  }

  useEffect(() => {
    if (!selectedEntry) {
      const initialObj = {
        location_id: location?.id,
        delivery_date: location?.delivery_date
          ? moment.utc(location?.delivery_date).startOf('day').toDate()
          : null,
        pickup_date: location?.pickup_date
          ? moment.utc(location?.pickup_date).startOf('day').toDate()
          : null,
        flatrate: 0,
        daily_rate: 0,
        pickup_rate: 0,
        delivery_rate: 0,
        is_pickup_fixed: false,
        maintenance_intervals: [],
      }

      setReqObj(initialObj)
    } else {
      const editObj = {
        ...selectedEntry,
        delivery_date: selectedEntry.delivery_date
          ? moment.utc(selectedEntry.delivery_date).startOf('day').toDate()
          : null,
        pickup_date: selectedEntry.pickup_date
          ? moment.utc(selectedEntry.pickup_date).startOf('day').toDate()
          : null,
        maintenance_intervals:
          selectedEntry.maintenance_intervals?.map(interval => ({
            ...interval,
            start_date: moment.utc(interval.start_date).startOf('day').toDate(),
            end_date: moment.utc(interval.end_date).startOf('day').toDate(),
          })) || [],
      }
      setReqObj(editObj)
    }
  }, [selectedEntry, location])

  const loadFacilities = async inputValue => {
    const results = await GetFacilitiesByName(inputValue)

    return results.map(entry => ({
      value: entry.id,
      label: entry.name,
    }))
  }

  const addMaintenanceInterval = () => {
    setReqObj({
      ...reqObj,
      maintenance_intervals: [
        ...(reqObj.maintenance_intervals || []),
        {
          start_date: reqObj.delivery_date
            ? moment.utc(reqObj.delivery_date).startOf('day').toDate()
            : moment.utc().startOf('day').toDate(),
          end_date: reqObj.pickup_date
            ? moment.utc(reqObj.pickup_date).startOf('day').toDate()
            : moment.utc().add(1, "month").startOf('day').toDate(),
          interval_days: 7,
        },
      ],
    })
  }

  const updateMaintenanceInterval = (index, field, value) => {
    const newIntervals = [...(reqObj.maintenance_intervals || [])]
    newIntervals[index] = {
      ...newIntervals[index],
      [field]: ["start_date", "end_date"].includes(field)
        ? moment.utc(value).startOf('day').toDate()
        : value,
    }
    setReqObj({
      ...reqObj,
      maintenance_intervals: newIntervals,
    })
  }

  const removeMaintenanceInterval = index => {
    const newIntervals = [...(reqObj.maintenance_intervals || [])]
    newIntervals.splice(index, 1)
    setReqObj({
      ...reqObj,
      maintenance_intervals: newIntervals,
    })
  }

  return (
    <Modal size="xl" isOpen={modal} toggle={onClose} centered>
      <ModalHeader toggle={onClose} tag="h4">
        {selectedEntry ? "Anlage bearbeiten" : "Anlage hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Datum Lieferung:</Label>

                <input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.delivery_date
                      ? moment.utc(reqObj.delivery_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      delivery_date: moment.utc(e.target.value).startOf('day').toDate(),
                    })
                  }
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">Datum Abholung:</Label>

                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.pickup_date
                      ? moment.utc(reqObj.pickup_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      pickup_date: moment.utc(e.target.value).startOf('day').toDate(),
                    })
                  }
                />
              </div>
              <div className="mb-2 d-flex align-items-center">
                <input
                  id="cbox_ispickupfixed"
                  type="checkbox"
                  className="ms-1 me-2"
                  checked={reqObj?.is_pickup_fixed ?? false}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      is_pickup_fixed: e.target.checked,
                    })
                  }
                />
                <label
                  htmlFor="cbox_ispickupfixed"
                  className="mb-0"
                  style={{ cursor: "pointer" }}
                >
                  Datum Abholung ist fixiert
                </label>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">Datum Abmeldung:</Label>

                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.decommission_date
                      ? moment.utc(reqObj.decommission_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      decommission_date: moment.utc(e.target.value).startOf('day').toDate(),
                    })
                  }
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">WC-Anlage:</Label>
                <AsyncSelect
                  loadOptions={loadFacilities}
                  className="select2-selection w-100"
                  defaultValue={{
                    value: selectedEntry?.facility_id,
                    label: selectedEntry?.facility_name,
                  }}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      facility_id: selectedOption.value,
                    })
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Menge:</Label>
                <Input
                  type="number"
                  min="1"
                  value={reqObj?.quantity ?? ""}
                  onChange={e => {
                    const value = parseInt(e.target.value)
                    if (value > 0) {
                      setReqObj({ ...reqObj, quantity: value })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-5 mt-3">
                <div
                  className="d-flex justify-content-between align-items-center mb-2"
                  style={{ maxWidth: "530px" }}
                >
                  <label className="form-label">Wartungsintervalle:</label>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={addMaintenanceInterval}
                  >
                    + Intervall hinzufügen
                  </button>
                </div>
                {reqObj.maintenance_intervals?.map((interval, index) => (
                  <div
                    key={index}
                    className="d-flex gap-3 mb-2 align-items-end"
                  >
                    <div>
                      <Label className="form-label">Startdatum:</Label>
                      <Input
                        type="date"
                        value={moment.utc(interval.start_date).format("YYYY-MM-DD")}
                        onChange={e =>
                          updateMaintenanceInterval(
                            index,
                            "start_date",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <Label className="form-label">Enddatum:</Label>
                      <Input
                        type="date"
                        value={moment.utc(interval.end_date).format("YYYY-MM-DD")}
                        onChange={e =>
                          updateMaintenanceInterval(
                            index,
                            "end_date",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <Label className="form-label">Intervall (Tage):</Label>
                      <Input
                        type="number"
                        min="1"
                        value={interval.interval_days}
                        onChange={e =>
                          updateMaintenanceInterval(
                            index,
                            "interval_days",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => removeMaintenanceInterval(index)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Zustellgebühr:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.delivery_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        delivery_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-4">
                <Label className="form-label">Abholgebühr:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.pickup_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        pickup_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Pauschale:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.flatrate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        flatrate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Tagessatz: </Label>
                <Input
                  type="number"
                  min="0.0"
                  step="0.01"
                  value={reqObj?.daily_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        daily_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">
                  Tagessatz Ab: <small>(optional)</small>
                </Label>

                <input
                  type="date"
                  className="form-control"
                  disabled={moment.utc(reqObj.daily_rate_start_date).isBefore(
                    moment.utc()
                  )}
                  value={
                    reqObj?.daily_rate_start_date
                      ? moment.utc(reqObj.daily_rate_start_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      daily_rate_start_date: moment.utc(e.target.value).startOf('day').toDate(),
                    })
                  }
                />
                <small className="ms-2">
                  Kein Datum, bedeutet ab Aufstellung{" "}
                </small>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  Notiz: <small>(optional)</small>
                </Label>
                <Input
                  type="text"
                  value={reqObj?.note ?? ""}
                  onChange={e => setReqObj({ ...reqObj, note: e.target.value })}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <UserInfoModal
        show={error != undefined}
        message={error}
        onConfirm={_ => setError()}
      />
    </Modal>
  )
}

export default BookingModal
